import IRoute from '../interfaces/route.interface';
import HomePage from '../pages/HomePage';
import AndrewAzizWatchlist from '../pages/AndrewAzizWatchlist';
import StockPage from '../pages/StockPage';

const routes: IRoute[] = [
  {
    path: '/',
    exact: true,
    component: HomePage,
    name: 'The Best Tools for Day Traders | trooper.trading',
    protected: false,
  },
  {
    path: '/watchlist/andrew-aziz-how-to-day-trade-for-a-living',
    exact: true,
    component: AndrewAzizWatchlist,
    name: 'Day Trading Watchlist according to Andrew Aziz in How to Day Trade for a Living | trooper.trading',
    protected: false,
  },
  {
    path: '/stock/:ticker',
    exact: true,
    component: StockPage,
    name: 'Stock Page | trooper.trading',
    protected: false,
  }
];
export default routes;
