import React from 'react';
import { useEffect } from "react";
import IPageProps from '../interfaces/page.interface';
import Alerts from '../modules/Alerts';

const AndrewAzizWatchlist: React.FunctionComponent<IPageProps> = props => {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (
    <main>
      <Alerts type="watchlist" />
    </main>
  );
};

export default AndrewAzizWatchlist;
