// Source: https://github.com/embbnux/use-global-storage/blob/master/src/index.ts
/* eslint-disable require-jsdoc */
import { useState, useEffect } from 'react';
import RTStorage from '../RTStorage';

function useGlobalStorage({ storageOptions } : { storageOptions: any }) {
  const storage = new RTStorage(storageOptions);
  const useStorage = (key: string, initialData: any) => {
    const [data, setState] = useState(initialData);

    useEffect(() => {
      function handleStorageChange(data: any) {
        setState(data);
      }
      storage.getItem(key).then((lastData: any) => {
        if (lastData) {
          setState(lastData);
        }
      });
      const subscription = storage.subscribe(key, handleStorageChange);
      return () => {
        subscription.unsubscribe();
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setData = async(newData: any) => {
      let newValue;
      if (typeof newData === 'function') {
        newValue = newData(data);
      } else {
        newValue = newData
      }
      setState(newValue);
      await storage.setItem(key, newValue);
    }

    return [data, setData];
  }
  return useStorage;
};

export default useGlobalStorage
