import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import useApi from '../Hooks/useApi';
import Alert from './Alert';

const useAlerts = (alertType: string = ''): [
  Alert[],
  'idle' | 'error' | 'loading' | 'success'
] => {
  const [api] = useApi();
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const {status, data, error} = useQuery(
    [alertType],
    async ({queryKey}) => {
      const {data} = await api.get(
        `${process.env.REACT_APP_API_URL}/alerts/${queryKey}`
      );
      return data;
    },
    {
      refetchInterval: 300000,
      refetchIntervalInBackground: true
    }
  );
  useEffect(() => {
    if (error) {
      console.log(`Alerts retrieval error: ${error}`);
    }
  }, [error]);
  useEffect(() => {
    if (data) {
      setAlerts(data as Alert[]);
    } else {
      setAlerts([]);
    }
  }, [data]);

  return [alerts, status];
};

export default useAlerts;
