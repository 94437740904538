import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteComponentProps,
} from 'react-router-dom';
// import {useGlobalState} from './app/state';
import {QueryClient, QueryClientProvider} from 'react-query';
import GoogleFontLoader from 'react-google-font-loader';
/*
import './config/Amplify';
import {CognitoUser} from '@aws-amplify/auth';
import {
  CognitoUserSession,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
*/
import routes from './config/routes';
import './App.less';

export interface IApplicationProps {}

const App: React.FunctionComponent<IApplicationProps> = props => {
  /*
  const [authState, setAuthState] = useGlobalState('authState');
  const [, setUserEmail] = useGlobalState('userEmail');
  const [, setIdToken] = useGlobalState('idToken');
  const [store] = useGlobalState('store');

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, data) => {
      setAuthState(nextAuthState);

      const authData = data as CognitoUser;

      if (authData) {
        authData.getUserAttributes(
          (
            error: Error | undefined,
            attributes: CognitoUserAttribute[] | undefined
          ) => {
            if (attributes) {
              for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].getName() === 'email') {
                  setUserEmail(attributes[i].getValue());
                  break;
                }
              }
            }
          }
        );

        authData.getSession(
          (error: Error | null, session: CognitoUserSession | null) => {
            if (session) {
              store.set('accessToken', session.getAccessToken().getJwtToken());
              setIdToken(session.getIdToken().getJwtToken());
            }
          }
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  useEffect(() => {
    if ("Notification" in window &&
      Notification.permission !== "granted" &&
      Notification.permission !== "denied") {

      Notification.requestPermission()
    }
  }, []);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Poppins',
              weights: [400],
            },
          ]}
        />
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(routeProps: RouteComponentProps<any>) => {
                return <route.component title={route.name} {...routeProps} />;
              }}
            />
          ))}
        </Switch>
      </Router>
    </QueryClientProvider>
  )
};

export default App;

/*
    <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn
          slot="sign-in"
          headerText="Sign in to your account"
          usernameAlias="email"
          hideSignUp
        />

        <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />

        {authState === AuthState.SignedIn ? (
          <QueryClientProvider client={queryClient}>
            <Router>
              <GoogleFontLoader
                fonts={[
                  {
                    font: 'Poppins',
                    weights: [400],
                  },
                ]}
              />
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={(routeProps: RouteComponentProps<any>) => {
                      return <route.component {...routeProps} />;
                    }}
                  />
                ))}
              </Switch>
            </Router>
          </QueryClientProvider>
        ) : (
          <></>
        )}
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
*/
