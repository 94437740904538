import {useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import { Tag } from 'antd';
import Alert from './Alert';
import useAlerts from './useAlerts';
import useGlobalStorage from '../Hooks/useGlobalStorage';
import useWebSocket from 'react-use-websocket';
import { NewspaperIcon } from '@heroicons/react/solid'

interface AlertsProps extends RouteComponentProps {
  type: string;
}

const Alerts = (props: AlertsProps) => {
  const useStorage = useGlobalStorage({
    storageOptions: { name: 'troopertrading' }
  });
  const [hiddenSymbols, setHiddenSymbols] = useStorage('hidden_ticker_symbols', []);
  const [sortDirection, setSortDirection] = useStorage('sort_direction', 1);
  const [sortFieldName, setSortFieldName] = useStorage('sort_field_name', 'shares_float');
  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(true);
  const [visibleFloatClasses, setVisibleFloatClasses] = useStorage('visible_float_classes', [
    'low', 'medium', 'high'
  ]);
  const [visibleLists, setVisibleLists] = useStorage('visible_lists', [
    'watchlist', 'momentum_zone', 'fast_gainer', 'reversal_top', 'reversal_bottom'
  ]);

  const [startupPhase, setStartupPhase] = useState<boolean>(true);
  const [oldAlertSymbols, setOldAlertSymbols] = useState<string[]>([]);
  const [filteredAlerts, setFilteredAlerts] = useState<Alert[]>([]);
  useEffect(() => {
    const newAlertSymbols: string[] = []
    for (const filteredAlert of filteredAlerts) {
      newAlertSymbols.push(`${filteredAlert.symbol}-${filteredAlert.subtype}`);
    }

    const addedAlertSymbolsAndTypes = newAlertSymbols.filter(x => !oldAlertSymbols.includes(x));

    // Don't give alerts for initial values at page start
    if (!startupPhase) {
      for (const addedAlertSymbolAndType of addedAlertSymbolsAndTypes) {
        const [addedAlertSymbol, addedAlertType]: string[] = addedAlertSymbolAndType.split('-');
        notifyAddedAlert(addedAlertSymbol, addedAlertType)
      }
    }

    setOldAlertSymbols(newAlertSymbols);
    setStartupPhase(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAlerts]);

  const [alerts, setAlerts] = useState<{ [symbol: string]: Alert }>({});
  useEffect(() => {
    const newFilteredAlerts: Alert[] = []
    for (const symbolAndType in alerts) {
      if (alerts.hasOwnProperty(symbolAndType)) {
        const alert: Alert = alerts[symbolAndType];
        let addItem = true;

        if (hiddenSymbols.includes(symbolAndType)) {
          addItem = false;
        }

        // Filter on list
        if (!visibleLists.includes(alert.subtype)) {
          addItem = false;
        }

        // Filter on shares float
        let floatClass = 'low';
        if (alert.shares_float >= 20000000) {
          floatClass = 'medium';
        }
        if (alert.shares_float > 500000000) {
          floatClass = 'high';
        }

        if (!visibleFloatClasses.includes(floatClass)) {
          addItem = false;
        }

        if (addItem) {
          newFilteredAlerts.push(alerts[symbolAndType]);
        }
      }
    }

    const sortField = sortFieldName as keyof Alert;
    const newFilteredSortedAlerts = newFilteredAlerts.sort((obj1, obj2) => {
      if (obj1[sortField] > obj2[sortField]) {
          return 1 * sortDirection;
      }

      if (obj1[sortField] < obj2[sortField]) {
          return -1 * sortDirection;
      }

      return 0;
    });

    setFilteredAlerts(newFilteredSortedAlerts);
  }, [alerts, hiddenSymbols, visibleFloatClasses, visibleLists, sortFieldName, sortDirection]);

  const [pulledAlerts, status] = useAlerts(props.type);
  useEffect(() => {
    const newAlerts: { [symbol: string]: Alert } = {};
    pulledAlerts.forEach( (alert: Alert) => {
      newAlerts[`${alert.symbol}-${alert.subtype}`] = alert;
    })
    setAlerts(newAlerts);
  }, [pulledAlerts]);

  const {lastJsonMessage} = useWebSocket('wss://6i4rm3p7e5.execute-api.eu-west-1.amazonaws.com/dev', {
    share: true,
    onOpen: () => console.log('opened'),
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });
  useEffect(() => {
    if (lastJsonMessage !== null) {
      lastJsonMessage.messages.forEach( (alert: Alert) => {
        console.log(alert)
        if (props.type != '' && alert.subtype != props.type) {
          console.log(`Dropped alert of type ${alert.subtype} for ${alert.symbol}`)
        } else {
          if (alert.active) {
            setAlerts( prevAlerts => {
              // Only update with newer information
              if (!(`${alert.symbol}-${alert.subtype}` in prevAlerts) || prevAlerts[`${alert.symbol}-${alert.subtype}`].detected_on_1m < alert.detected_on_1m) {
                const newAlerts = {...prevAlerts};
                newAlerts[`${alert.symbol}-${alert.subtype}`] = alert;
                return newAlerts;
              } else {
                return prevAlerts;
              }
            });
          } else {
            setAlerts( prevAlerts => {
              const newAlerts = {...prevAlerts};
              delete newAlerts[`${alert.symbol}-${alert.subtype}`]
              return newAlerts;
            });
          }
        }
      });
    }
  }, [lastJsonMessage]);

  const notifyAddedAlert = function(addedAlertSymbol: string, addedAlertSubtype: string) {
    if (notificationsEnabled) {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      }
      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        new Notification(addedAlertSubtype, {
          body: `New in ${addedAlertSubtype}: ${addedAlertSymbol}`,
          icon: `/img/${addedAlertSubtype}.png`
        });
      }
    }
  }

  /*
  const onHide = function(alert: Alert, e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    let newHiddenSymbols = hiddenSymbols
    newHiddenSymbols.push(alert.symbol)

    // Dedupe
    newHiddenSymbols = [...new Set(newHiddenSymbols)]

    // Sort
    newHiddenSymbols.sort()

    // Assign
    setHiddenSymbols(newHiddenSymbols);
  }
  */

  const onUnhide = function(symbolToUnhide: string, e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    setHiddenSymbols(hiddenSymbols.filter((symbol: any) => symbol !== symbolToUnhide));
  }

  // eslint-disable-next-line no-unused-vars
  enum AlignType {
    // eslint-disable-next-line no-unused-vars
    Left = "left",
    // eslint-disable-next-line no-unused-vars
    Right = "right",
    // eslint-disable-next-line no-unused-vars
    Center = "center",
  }

  // Given an ISO 8601 date string, render a human-friendly description
  // of how long ago it was, if recent.
  // Source: https://alexwlchan.net/2020/05/human-friendly-dates-in-javascript/
  // Examples:
  // - "just now"
  // - "10 seconds ago"
  // - "20 minutes ago"
  //
  const getHumanFriendlyDelta = function(isoDateString: string, minDeltaSeconds: number) {
    const date = new Date(Date.parse(isoDateString));
    const now = new Date();

    const deltaMilliseconds = now.getTime() - date.getTime();
    const deltaSeconds = Math.floor(deltaMilliseconds / 1000);

    if (deltaSeconds >= minDeltaSeconds) {
      const deltaMinutes = Math.floor(deltaSeconds / 60);
      const deltaHours = Math.floor(deltaMinutes / 60);
      const deltaDays = Math.floor(deltaHours / 24);

      if (deltaSeconds < 5) {
        return "just now";
      } else if (deltaSeconds < 60) {
        return deltaSeconds + " seconds ago";
      } else if (deltaMinutes == 1) {
        return "1 minute ago";
      } else if (deltaMinutes < 60) {
        return deltaMinutes + " minutes ago";
      } else if (deltaHours == 1) {
        return "1 hour ago";
      } else if (deltaHours < 24) {
        return deltaHours + " hours ago";
      } else if (deltaDays == 1) {
        return "1 day ago";
      } else {
        return deltaDays + " days ago";
      }
    } else {
      return "";
    }
  }

  const alertSubtypeToColor = function(subtype: string) {
    switch(subtype) {
      case 'watchlist':
        return 'bg-cyan-100';
      case 'momentum_zone':
        return 'bg-violet-100';
      case 'fast_gainer':
        return 'bg-pink-100';
      default:
        return 'bg-slate-100';
    }
  }

  const toggleVisibleFloatClass = function(className: string) {
    if (visibleFloatClasses.includes(className)) {
      setVisibleFloatClasses(visibleFloatClasses.filter(function(value: string) {
        return value != className;
      }));
    } else {
      setVisibleFloatClasses([...visibleFloatClasses, className]);
    }
  }

  const toggleVisibleList = function(listName: string) {
    if (visibleLists.includes(listName)) {
      setVisibleLists(visibleLists.filter(function(value: string) {
        return value != listName;
      }));
    } else {
      setVisibleLists([...visibleLists, listName]);
    }
  }

  return (
    <div>
      {hiddenSymbols.map((tag: string) => {
        return (
          <Tag
            key={tag}
            closable
            onClose={e => { e.preventDefault(); onUnhide(tag, e); }}
          >
            {tag}
          </Tag>
        );
      })}

      <div className="bg-slate-100 w-screen p-5">
        <div className="pb-4">
          Status: {status} |
          <button
            className={`rounded-full mx-1 px-2 ${notificationsEnabled ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { setNotificationsEnabled(!notificationsEnabled); }}>notifications</button> |
          Float:
          <button
            className={`rounded-full mx-1 px-2 ${visibleFloatClasses.includes('low') ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { toggleVisibleFloatClass('low'); }}>low</button>
          <button
            className={`rounded-full mx-1 px-2 ${visibleFloatClasses.includes('medium') ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { toggleVisibleFloatClass('medium'); }}>medium</button>
          <button
            className={`rounded-full mx-1 px-2 ${visibleFloatClasses.includes('high') ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { toggleVisibleFloatClass('high'); }}>high</button>
          |
          Lists:
          <button
            className={`rounded-full mx-1 px-2 ${visibleLists.includes('watchlist') ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { toggleVisibleList('watchlist'); }}>watchlist</button>
          <button
            className={`rounded-full mx-1 px-2 ${visibleLists.includes('momentum_zone') ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { toggleVisibleList('momentum_zone'); }}>momentum zone</button>
          <button
            className={`rounded-full mx-1 px-2 ${visibleLists.includes('fast_gainer') ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { toggleVisibleList('fast_gainer'); }}>fast gainer</button>
          <button
            className={`rounded-full mx-1 px-2 ${visibleLists.includes('reversal_top') ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { toggleVisibleList('reversal_top'); }}>reversal top</button>
          <button
            className={`rounded-full mx-1 px-2 ${visibleLists.includes('reversal_bottom') ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { toggleVisibleList('reversal_bottom'); }}>reversal bottom</button>
          |
          Sort:
          <button
            className={`rounded-full mx-1 px-2 ${sortDirection == 1 ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { setSortDirection(1); }}>ascending</button>
          <button
            className={`rounded-full mx-1 px-2 ${sortDirection == -1 ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { setSortDirection(-1); }}>descending</button>
          on
          <button
            className={`rounded-full mx-1 px-2 ${sortFieldName == 'shares_float' ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { setSortFieldName('shares_float'); }}>shares float</button>
          <button
            className={`rounded-full mx-1 px-2 ${sortFieldName == 'relative_volume' ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { setSortFieldName('relative_volume'); }}>relative volume</button>
          <button
            className={`rounded-full mx-1 px-2 ${sortFieldName == 'change_vs_prev_close' ? "bg-lime-300" : "outline outline-slate-300" }`}
            onClick={(e) => { setSortFieldName('change_vs_prev_close'); }}>change vs prev close</button>
        </div>

        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
          {filteredAlerts.map(function(alert) {
            return <div key={`${alert.symbol}-${alert.subtype}`} className="h-64 p-0 bg-white rounded-xl shadow-md overflow-hidden">
              <div className={`px-4 pt-2 pb-1 ${alertSubtypeToColor(alert.subtype)}`}>
                <p className="align-text-bottom font-sans text-slate-600 text-xs font-extralight float-right">{getHumanFriendlyDelta(alert.detected_on_1m, 180)}</p>
                <p className="align-text-bottom font-sans text-slate-600 text-xs font-regular truncate">{alert.subtype}</p>
              </div>

              <div className="px-4 py-2">
                <div className="pb-3">
                {alert.latest_news && alert.latest_news.length > 0 &&
                  <NewspaperIcon className="h-6 w-6 text-slate-500 float-right"/>
                }
                  <p className="font-sans text-slate-900 text-xl font-extrabold">{alert.symbol}</p>
                  <p className="font-sans text-slate-600 font-light truncate">{alert.name}</p>
                </div>

                <table className="table-auto">
                  <tbody className="font-sans font-regular">
                    <tr>
                      <td className="align-text-bottom text-right text-m text-slate-900 tabular-nums">
                        {new Intl.NumberFormat('en-EN', { style: 'percent', maximumFractionDigits: 0 }).format(alert.relative_volume)}
                      </td>
                      <td className="align-text-bottom text-slate-700 text-xs">&nbsp;relative volume</td>
                    </tr>
                    <tr>
                      <td className="align-text-bottom text-right text-m text-slate-900 tabular-nums">
                      {alert.shares_float < 20000000 &&
                        <button className="text-xs rounded-full mx-1 px-2 bg-red-200">low</button>
                      }
                      {alert.shares_float >= 20000000 && alert.shares_float <= 500000000 &&
                        <button className="text-xs rounded-full mx-1 px-2 bg-yellow-200">medium</button>
                      }
                      {alert.shares_float > 500000000 &&
                        <button className="text-xs rounded-full mx-1 px-2 bg-green-200">high</button>
                      }
                        {new Intl.NumberFormat('en-EN', { notation: 'compact' }).format(alert.shares_float)}
                      </td>
                      <td className="align-text-bottom text-slate-700 text-xs">&nbsp;shares float</td>
                    </tr>
                    <tr>
                      <td className="align-text-bottom text-right text-m tabular-nums">
                        {new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(alert.last_1m_close)}
                      </td>
                      <td className="align-text-bottom text-slate-700 text-xs">&nbsp;last price</td>
                    </tr>
                  </tbody>
                </table>

                <div className="grid grid-cols-3 gap-0 font-sans font-regular text-center">
                  <div className={`align-text-bottom text-m tabular-nums ${alert.change_vs_prev_close - alert.change_vs_open > 0 ? "text-green-600" : "text-red-600" }`}>
                    {alert.change_vs_prev_close - alert.change_vs_open > 0 ? '+' : '' }
                    {new Intl.NumberFormat('en-EN', { style: 'percent', maximumFractionDigits: 1 }).format(alert.change_vs_prev_close - alert.change_vs_open)}
                  </div>
                  <div className={`align-text-bottom text-m tabular-nums ${alert.change_vs_open > 0 ? "text-green-600" : "text-red-600" }`}>
                    {alert.change_vs_open > 0 ? '+' : '' }
                    {new Intl.NumberFormat('en-EN', { style: 'percent', maximumFractionDigits: 1 }).format(alert.change_vs_open)}
                  </div>
                  <div>

                  </div>

                  <div className="align-text-top text-slate-700 text-xs">
                    after-market
                  </div>
                  <div className="align-text-top text-slate-700 text-xs">
                    pre-market
                  </div>
                  <div className="align-text-top text-slate-700 text-xs">
                    market
                  </div>
                </div>
              </div>
            </div>
            })}
          </div>
      </div>
    </div>
  );
};

export default withRouter(Alerts);
